<template>
	<v-sheet class="location" id="location" style="height: calc(100vh - 88px)">
		<CreateDialog :dialog="createDialog" v-on:close="(createDialog = false), clearLocalData()">
			<template v-slot:title>
				<span>{{ pageTitle() }} </span>
				<span class="orange--text text--darken-4 barcode-font-size">#{{ services.barcode }}</span>
			</template>
			<template v-slot:body>
				<template v-if="!barcodeLoading">
					<v-form
						ref="serviceForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate"
					>
						<v-row>
							<v-col md="8">
								<perfect-scrollbar
									:options="{ suppressScrollX: true }"
									class="scroll custom-box-top-inner-shadow"
									style="max-height: calc(100vh - 140px); position: relative"
								>
									<v-row class="px-4">
										<!-- <v-col md="3" class="my-auto py-0">
											<label for="service-id" class="btx-label mt-2 required">Service #</label>
										</v-col>
										<v-col md="9" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading || uuid ? true : false"
												:loading="pageLoading"
												id="service-id"
												placeholder="Service #"
												:rules="[vrules.required(services.barcode, 'Service #'), barcodeError ? false : true]"
												:class="{
													required: !services.barcode,
												}"
												v-model="services.barcode"
												v-on:change="validateBarcode(services.barcode, 'service'), savetoLocal()"
											>
											</TextInput>
											<span v-if="barcodeError" class="red--text text--darken-1 font-small">{{
												barcodeError
											}}</span>
										</v-col> -->
										<v-col md="3" class="my-auto py-0">
											<label for="name" class="btx-label mt-2 required">Name </label>
										</v-col>
										<v-col md="9" class="py-0">
											<TextInput
												productNameValidate
												:disabled="pageLoading"
												:loading="pageLoading"
												id="name"
												placeholder="Name"
												v-model="services.name"
												@change="savetoLocal()"
												:rules="[vrules.required(services.name, 'Service Name')]"
												:class="{
													required: !services.name,
												}"
											></TextInput>
										</v-col>
										<v-col md="3" class="py-0">
											<label for="description" class="btx-label mt-2">Description</label>
										</v-col>
										<v-col md="9" class="py-0">
											<TextAreaInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="description"
												placeholder="Description"
												v-model="services.description"
												@change="savetoLocal()"
											>
											</TextAreaInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="reference" class="btx-label mt-2">Reference </label>
										</v-col>
										<v-col md="9" class="py-0">
											<TextInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="reference"
												placeholder="Reference"
												v-model="services.reference"
												@change="savetoLocal()"
											>
											</TextInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="charges" class="btx-label mt-2">Charges </label>
										</v-col>
										<v-col md="9" class="py-0">
											<PriceInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												id="charges"
												type="number"
												prepend-inner-icon="mdi-currency-usd"
												placeholder="Charges"
												v-model="services.charges"
												@change="savetoLocal(), limitDecimalPriceInput()"
											>
											</PriceInput>
										</v-col>
										<v-col md="3" class="my-auto py-0">
											<label for="group" class="btx-label mt-2 required">Category</label>
										</v-col>
										<v-col md="9" class="py-0">
											<AutoCompleteInput
												hide-details
												:items="groupList"
												:disabled="pageLoading"
												:loading="pageLoading"
												id="group"
												:rules="[vrules.required(services.category, 'Category')]"
												:class="{
													required: !services.category,
												}"
												placeholder="Category"
												v-model="services.category"
												:append-outer-icon="getPermission('group::view') == true ? 'mdi-cog' : ''"
												v-on:click:append-outer="manageCategoryDialog = true"
												@change="savetoLocal()"
											></AutoCompleteInput>
										</v-col>
									</v-row>
								</perfect-scrollbar>
							</v-col>
							<v-col md="4">
								<v-row>
									<v-col md="12" class="my-6 py-0 text-center">
										<ImageUpload can-change v-model="services.image" :page-loading="pageLoading">
										</ImageUpload>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-form>
				</template>
				<template v-else>
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
			</template>
			<template v-slot:action>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					@click="updateOrCreate()"
				>
					{{ uuid ? "Update" : "Save" }}
				</v-btn>
			</template>
		</CreateDialog>
		<ManageCategory
			:dialog="manageCategoryDialog"
			:category="groupList"
			v-on:close-dialog="manageCategoryDialog = false"
			v-on:success="getCategories"
		></ManageCategory>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateService, UpdateService, GetService } from "@/core/lib/service.lib";
import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import ImageUpload from "@/view/components/ImageUpload";
import ManageCategory from "@/view/components/Manage-Category";
import CreateDialog from "@/view/components/CreateDialog";
import { saveData, getData, deleteData } from "@/core/services/local.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PriceInput from "@/view/components/PriceInput";

export default {
	name: "Service-create",
	title: "Create Service",
	data() {
		return {
			uuid: null,
			barcode: null,
			createDialog: true,
			formValid: true,
			pageLoading: false,
			manageCategoryDialog: false,
			location: {
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			services: {
				name: null,
				description: null,
				reference: null,
				barcode: null,
				charges: null,
				category: null,
				image: null,
			},
			groupList: [],
		};
	},
	components: {
		CreateDialog,
		TextInput,
		AutoCompleteInput,
		TextAreaInput,
		ImageUpload,
		ManageCategory,
		PriceInput,
	},
	methods: {
		getSubGroup() {
			console.log({ services_category: this.services.category });
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.serviceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.serviceForm.validate()) {
				return false;
			}

			const formData = {
				barcode: null,
				name: this.services.name,
				description: this.services.description,
				reference: this.services.reference,
				charges: this.services.charges,
				category: this.services.category,
				product_type: "service",
				image: this.services.image,
			};

			try {
				_this.pageLoading = true;

				if (_this.uuid) {
					// formData.type = this.locationType;
					// formData.user = this.locationTypeId;

					const { uuid } = await UpdateService(_this.uuid, formData);

					_this.$router.replace({
						name: "service-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Service has been updated." },
					]);
				} else {
					const { uuid } = await CreateService(formData);

					_this.$router.replace({
						name: "service-detail",
						params: { uuid },
						query: { t: new Date().getTime() },
					});

					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Service has been created." },
					]);
				}
				// clearing local storage data
				deleteData("create_service");
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		clearLocalData() {
			deleteData("create_service");
		},
		getService() {
			GetService(this.uuid)
				.then((data) => {
					this.barcode = data.barcode;
					this.services.name = data.name;
					this.services.description = data.description;
					this.services.reference = data.reference;
					this.services.barcode = data.barcode;
					this.services.charges = data.charges;
					this.services.category = data.group;
					this.services.image = data.image;

					/* this.barcode = data.barcode;
					this.locationType = data.type == 2 ? "user" : "contact";
					this.locationTypeId = data.type == 2 ? data.user : data.contact;
					this.location.name = data.name;
					this.location.id_number = data.id_number;
					this.location.location = data.location;
					this.location.latitude = data.latitude;
					this.location.longitude = data.longitude;
					this.location.address_line_1 = data.address_line_1;
					this.location.address_line_2 = data.address_line_2;
					this.location.address_city = data.address_city;
					this.location.address_state = data.address_state;
					this.location.address_country = data.address_country;
					this.location.address_postal_code = data.address_postal_code;
					this.location.description = data.description;
					this.location.has_lat_long = data.has_lat_long; */

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Service", disabled: true },
						{ text: "Update", disabled: true },
						{ text: this.barcode, disabled: true },
					]);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCategories(param) {
			const isCategoryExist = param.some((ele) => ele.id === this.services.category);
			if (!isCategoryExist) {
				this.services.category = null;
			}
			this.groupList = param;
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Service";
			}
			return "Create new Service";
		},
		savetoLocal() {
			if (!this.uuid) {
				const create_service = {
					barcode: this.services.barcode,
					name: this.services.name,
					reference: this.services.reference,
					charges: this.services.charges,
					category: this.services.category,
					description: this.services.description,
				};
				saveData("create_service", create_service);
			}
		},
		limitDecimalPriceInput() {
			let string = this.services.charges.toString();
			if (string.slice(string.indexOf(".") + 1, string.length + 1).length > 2) {
				this.services.charges = this.services.charges.toFixed(2);
			}
		},
	},
	mounted() {
		this.groupList = this.localDB("groups", []);
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Service", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;
		if (name === "service-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getService();
		} else {
			this.genrateBarcode("service").then((output) => {
				this.services.barcode = output;
			});
		}
		// getting service local storage data
		let localSaveService = getData("create_service");
		if (localSaveService) {
			this.services.barcode = localSaveService.barcode;
			this.services.name = localSaveService.name;
			this.services.description = localSaveService.description;
			this.services.reference = localSaveService.reference;
			this.services.charges = localSaveService.charges;
			this.services.category = localSaveService.category;
		}
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
